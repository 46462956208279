import React, {useState} from "react";
import {FinalData, FormValues, Item, OrderItems, UserData} from "../../types/types";
import {Link, Redirect, RouteComponentProps, useHistory, useLocation, withRouter} from "react-router-dom";
import {StaticContext} from "react-router";
import axios from "axios";
import SaveSystemService from "../../services/save-system-service";

type LocationState = {
    items: OrderItems,
    userData: UserData,
    formData: FormValues,
    isAuthorised: boolean,
    mode: string,
    callPopup: (message: string, type: string) => void
}

const OrderSummary = (prop: RouteComponentProps<{}, StaticContext, LocationState>) => {

    let history = useHistory()
    const location = useLocation<LocationState>()
    const [loading, setLoading] = useState(false)
    const [addedItems, setAddedItems] = useState<number[]>([])

    if (prop.location.state === undefined){
        return <Redirect to='/'/>
    }

    console.log(location.state)
    const items = location.state.items.items
    const additionalItems = location.state.items.additionalItems
    const rate = location.state.items.rate ? location.state.items.rate : 1
    const userData = location.state.userData
    const formData = location.state.formData
    const callPopup = location.state.callPopup

    let index = 0

    const upendAddedItems = () => {
        let index = 0
        if (addedItems.length !== 0) {
            index = addedItems[addedItems.length - 1] + 1
        }
        setAddedItems([...addedItems, index])
    }

    const deleteAddedItems = (index: number) => {
        const newArray = addedItems.filter(item => item !== index)
        setAddedItems(newArray)
    }

    const sendToServer = () => {
        const api = axios.create({
            baseURL: 'https://wholesale.markiz.by/'
        })
        setLoading(true)
        callPopup('Данные обрабатываются сервером. Пожалуйста подождите!', 'message')

        let finalData: FinalData

        if (items) {
            finalData = {
                companyInformation: userData,
                itemsInformation: location.state.items,
                orderIdInBase: ''
            }
        } else {
            setLoading(false)
            callPopup('Недостаточно данных для отправки', 'error')
            return
        }

        const SaveSystem = new SaveSystemService()
        SaveSystem.saveOrder(userData.mail, formData, 'Сохранён', +sumOrder()).then((res_save) => {
            if(res_save){
                api.post('/getLastOrderId.php').then((res_id) => {
                    if(res_id){
                        console.log('RES_ID', res_id)
                    }
                    finalData.orderIdInBase = res_id.data
                    api.post('/mainScript.php', finalData).then((res) => {
                        if (res.data ==='Message sent!') {
                            setLoading(false)
                            callPopup('Заказ был успешно отправлен!', 'success')

                            history.push({pathname: '/user-panel'})
                        } else {
                            setLoading(false)
                            callPopup(res.data, 'error')
                        }
                    })
                })
            } else {
                setLoading(false)
                callPopup('Не удалось записать заказ.', 'error')
            }
        })
    }

    const sumRecomendedPrice = () => {
        let finalPrice = 0
        items?.forEach((item) => {
            finalPrice += mult(item.recommendedPriceEuro, rate)
        })
        additionalItems?.forEach((item) => {
            finalPrice += mult(item.recommendedPriceEuro * rate, item.quantity)
        })
        return finalPrice.toFixed(2)
    }

    const sumOrder = () => {
        let sum = 0;

        items?.forEach((item) => {
            sum += mult(item.priceEuro, rate)
        })

        additionalItems?.forEach((item) => {
            sum += mult(item.priceEuro * item.quantity, rate)
        })

            return sum.toFixed(2)
    }

    const totalRecalc = () => {
        let total = 0
        // @ts-ignore
        const table: HTMLTableElement = document.getElementById('displayTable')
        for(let i = 1; i<table.rows.length - 1; i++){
            total+=Number(table.rows[i].cells[4].innerText)
        }
        table.rows[table.rows.length-1].cells[4].innerText = String(mult(total, 1))
    }

    const elementsRecalc = () => {
        // @ts-ignore
        const table: HTMLTableElement = document.getElementById('displayTable')
        for(let i = 1; i<table.rows.length - 1; i++){
            const quantity = Number(table.rows[i].cells[2].innerText)
            const price = Number(table.rows[i].cells[3].innerText)
            table.rows[i].cells[4].innerText = String(mult(quantity,price))
        }
    }

    const recalculate = () => {
        elementsRecalc()
        totalRecalc()
    }

    const mult = (x: number, y: number | undefined) => {
        if (!y) {
            y = 1
        }
        console.log(x)
        console.log(y)
        console.log(x*y)
        if(x*y === 0){
            return 0
        }
        return Math.ceil((x*y)*100)/100
    }

    const currency = (country: string) => {
        if (rate === 1){
            return 'Cтоимость единицы (EUR)'
        }

        switch (country) {
            case 'Россия': {
                return 'Cтоимость единицы (RUB)'
            }
            case 'Беларусь': {
                return 'Cтоимость единицы (BYN)'
            }
            case 'Казахстан': {
                if (userData.role === 'Dealer') {
                    return 'Cтоимость единицы (EUR)'
                } else {
                    return 'Cтоимость единицы (KZT)'
                }
            }
        }
    }

    const imageObjects = (items: Item[]) => {
        const images = {
            "Пергольная маркиза Traffico.\n": {
                name: "Пергольная маркиза Traffico.\n",
                links: [
                    'Traffico/A4_fuoco_base 10.jpg',
                    'Traffico/IMG_3250.JPG',
                    'Traffico/IMG_3254.JPG',
                    'Traffico/IMG_3257.JPG',
                    'Traffico/IMG_4142.JPG'
                ]
            },
            "Купольные маркизы CLASSIC.\n": {
                name: "Купольные маркизы CLASSIC.\n",
                links: [
                    'Classic/image.png',
                    'Classic/0-02-05-01193137b6f67fb75d41657024a2adb9e96f53f46c3c2ab2c3fab6ef25a3f930_a3776622.jpg',
                    'Classic/IMG_2806.JPG',
                    'Classic/IMG_2834.JPG'
                ]
            },
            "Купольные маркизы MODERN.\n": {
                name: "Купольные маркизы MODERN.\n",
                links: [
                    'Modern/image (1).png',
                    'Modern/image (2).png',
                    'Modern/image (3).png',
                    'Modern/IMG_2399.JPG'
                ]
            },
            "Локтевые маркизы Helix.\n": {
                name: "Локтевые маркизы Helix.\n",
                links: [
                    'Helix/image (4).png',
                    'Helix/image (5).png',
                    'Helix/image (6).png',
                    'Helix/unnamed.png',
                    'Helix/2021-06-24 21.18.51.jpg',
                    'Helix/2022-06-26 09.18.42.jpg',
                    'Helix/2022-06-26 09.20.35.jpg'
                ]
            },
            "Локтевые маркизы Optima.\n": {
                name: "Локтевые маркизы Optima.\n",
                links: [
                    'Optima/image (7).png',
                    'Optima/unnamed.png',
                    'Optima/0-02-05-1aaf332f3e3e9ff005e58f10259235c8c2411c55a8b7e9b6a22f25c5726ec32f_80aa64df.jpg',
                    'Optima/0-02-05-bb9a5c89ac1f158a1af3a0ed914660c684e876afab296a9592da2f1b35177bb7_76da3fd2cd699cdd.jpg',
                    'Optima/2021-05-27 17.20.46.jpg',
                    'Optima/2021-05-27 17.21.14.jpg',
                    'Optima/2021-06-24 21.20.34.jpg'
                ]
            },
            "Локтевые маркизы Optima PLUS.\n": {
                name: "Локтевые маркизы Optima PLUS.\n",
                links:[
                    'OptimaPlus/image (10).png',
                    'OptimaPlus/image (9).png',
                    'OptimaPlus/image (8).png',
                    'OptimaPlus/0-02-05-1aaf332f3e3e9ff005e58f10259235c8c2411c55a8b7e9b6a22f25c5726ec32f_80aa64df.jpg',
                    'OptimaPlus/0-02-05-bb9a5c89ac1f158a1af3a0ed914660c684e876afab296a9592da2f1b35177bb7_76da3fd2cd699cdd.jpg',
                    'OptimaPlus/2021-06-24 21.20.34.jpg'
                ]
            },
            "Локтевые маркизы Гармония.\n": {
                name: "Локтевые маркизы Гармония.\n",
                links: [
                    'Garmonia/image (11).png',
                    'Garmonia/image (12).png',
                    'Garmonia/image (13).png',
                    'Garmonia/image (14).png',
                    'Garmonia/image (15).png'
                ]
            },
            "Локтевые маркизы VIKTORY.\n": {
                name: "Локтевые маркизы VIKTORY.\n",
                links: [
                    'Victory/image (16).png',
                    'Victory/image (17).png',
                    'Victory/image (18).png',
                    'Victory/image (19).png',
                    'Victory/image (20).png',
                    'Victory/0-02-05-723201bf706adf47156cd3a290116ead98c33e1e579bb1d8590724d7a314b5ca_9d9dac7026e70c9a.jpg'
                ]
            },
            "Локтевые маркизы Domea.\n": {
                name: "Локтевые маркизы Domea.\n",
                links:  [
                    'Domea/image (21).png',
                    'Domea/image (22).png',
                    'Domea/image (23).png',
                    'Domea/image (24).png',
                    'Domea/image (25).png',
                    'Domea/image (26).png'
                ]
            },
            "Витринные маркизы ITALIA.\n": {
                name: "Витринные маркизы ITALIA.\n",
                links: [
                    'Italia/image (27).png',
                    'Italia/image (28).png',
                    'Italia/image (29).png',
                    'Italia/IMG_0155.jpg'
                ]
            },
            "Вертикальные маркизы (экраны) ASN 130 GPZ.\n": {
                name: "Вертикальные маркизы (экраны) ASN 130 GPZ.\n",
                links: [
                    'ASN130GPZ/img.png',
                    'ASN130GPZ/0-02-05-d7950d2a38d85a187715d2348ad0c5d243b8c8e01d63e6d5510d3e254e3ffffe_d751e1932ae217f1.jpg',
                    'ASN130GPZ/0-02-05-dd2383c1be5cb288a347026ffed965832a96cc9acefefe188a30f4c67278b52a_59b297dcabd9ffde.jpg',
                    'ASN130GPZ/0-02-05-e45965c1fbeb90b40523e64afa218099b3e02dbd9f454d01826a1b19b6472406_6903f4b1ea7384f1.jpg',
                    'ASN130GPZ/1653309861620871.jpg'
                ]
            },
            "Вертикальные маркизы (экраны) ASN 110 cabrio.\n": {
                name: "Вертикальные маркизы (экраны) ASN 110 cabrio.\n",
                links: [
                    'ASN110Cab/image (30).png',
                    'ASN110Cab/image (31).png',
                    'ASN110Cab/image (32).png'
                ]
            },
            "Вертикальные маркизы (экраны) ASN 130 GPZ TENZ.\n": {
                name: "Вертикальные маркизы (экраны) ASN 130 GPZ TENZ.\n",
                links: [
                    'ASN130GPZTens/image (33).png',
                    'ASN130GPZTens/image (34).png',
                    'ASN130GPZTens/image (35).png',
                    'ASN130GPZTens/image (36).png',
                    'ASN130GPZTens/image (37).png',
                    'ASN130GPZTens/Речная _2020-01-20_09-36-35.jpg'
                ]
            },
            'Вертикальные маркизы (экраны) ASN 130 Эконом.\n': {
                name: "Вертикальные маркизы (экраны) ASN 130 Эконом.\n",
                links: [
                    'Econom/img.png',
                    'Econom/viber_2023-07-05_14-46-56-153.jpg',
                    'Econom/viber_2023-07-05_14-46-56-396.jpg',
                    'Econom/viber_2023-07-05_14-46-56-766.jpg',
                    'Econom/viber_2023-07-05_14-46-56-576.jpg'
                ]
            },
            "Тент на люверсах.\n": {
                name: "Тент на люверсах.\n",
                links: [
                    'Tent/image (38).png',
                    'Tent/image (39).png'
                ]
            },
        }

        let imageData = items.map((item) => {
            // @ts-ignore
            return images[item.name]
        })
        return imageData.filter((value, index) => imageData.indexOf(value) === index)
    }

    const itemDescription = (item: Item) => {
        switch (item.name) {
            case 'Тент на люверсах.\n':
                return(
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}. {item.tentControlSide ? item.tentControlSide+'.' : ''}<br/>
                    </td>
                    )
            case 'Выставочный стенд':
                return (
                    <td>
                        {item.name}<br/>
                    </td>
                )
            case 'Вертикальные маркизы (экраны) ASN 130 GPZ.\n':
            case 'Вертикальные маркизы (экраны) ASN 110 cabrio.\n':
            case 'Вертикальные маркизы (экраны) ASN 130 GPZ TENZ.\n':
                return (
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}. {item.tentControlSide ? item.tentControlSide+'.' : ''}<br/>
                        В комплекте: {item.electricDriver}. {item.electricDriverSide}.<br/>
                        Цвет профилей: {item.color}.
                    </td>
                )
            case 'Вертикальные маркизы (экраны) ASN 130 Эконом.\n':
                return (
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}.<br/>
                        Цвет профилей: {item.color}.
                        В комплекте: Ручное управление. {item.electricDriverSide}.<br/>
                    </td>
                )
            case 'Локтевые маркизы Domea.\n':
                return (
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}. {item.tentControlSide ? item.tentControlSide+'.' : ''}<br/>
                        В комплекте: {item.electricDriver}. {item.electricDriverSide}.<br/>
                        Цвет профилей: {item.color}.
                    </td>
                )
            case 'Купольные маркизы CLASSIC.\n':
            case 'Купольные маркизы MODERN.\n':
            case 'Локтевые маркизы Optima.\n':
            case 'Локтевые маркизы Helix.\n':
            case 'Локтевые маркизы Optima PLUS.\n':
            case 'Локтевые маркизы VIKTORY.\n':
            case 'Локтевые маркизы Гармония.\n':
            case 'Витринные маркизы ITALIA.\n':
                return (
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Тип волана: {item.volan}.<br/>
                        Тип крепления: {item.montageType}<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}.<br/>
                        Управление: {item.electricDriver} {item.electricDriverSide}<br/>
                        Цвет профилей: {item.color}.
                    </td>
                )
            case 'Пергольная маркиза Traffico.\n':
                return (
                    <td>
                        {item.name}<br/>
                        Размеры: ширина {item.width} мм, выпуск {item.height} мм.<br/>
                        Артикул ткани: {item.tentManufactor} {item.tentArticle}.<br/>
                        Управление: Автоматическое управление<br/>
                        Заполнение: {item.triangle}<br/>
                        Кол-во траверс: {item.travers}<br/>
                        Цвет профилей: {item.color}.
                    </td>
                )
        }
    }

    return(
        <div>
            {
                (loading) && (
                    <div className='inset-0 z-40 absolute w-full h-screen'>
                        <div className='absolute bg-black opacity-50 w-full h-screen'>
                        </div>
                        <div className='absolute grid top-1/4 left-1/4 bg-white opacity-100 rounded h-1/2 w-1/2 p-10 justify-center'>
                            <p className='text-3xl'>Идет обработка заказа. Пожалуйста подождите!</p>
                            <div className='loader justify-self-center'></div>
                        </div>
                    </div>
                )
            }
            <div className='print:hidden absolute right-10 '>
                Курс евро: {rate}
            </div>
            {
                (location.state.mode === 'recalculate') && (
                    <div className='flex flex-col w-96 ml-5'>
                        <p className='invisible print:visible'>От: {userData.name} {userData.address}</p>
                        <div>Для: <p className='inline-block' contentEditable={true}>Впишите название фирмы</p></div>
                    </div>
                )
            }
            <div className='flex w-full mt-10 justify-center'>
                <table className='table-auto' id='displayTable'>
                    <thead>
                    {
                        (location.state.mode === 'recalculate') ?
                            <tr className='bg-gray-50'>
                                <th scope="col">#</th>
                                <th scope="col">Наименование</th>
                                <th scope="col">Кол-во</th>
                                <th scope="col">{currency(userData.country)}</th>
                                <th scope="col">Всего:</th>
                            </tr>
                            :
                            <tr className='bg-gray-50'>
                                <th scope="col">#</th>
                                <th scope="col">Наименование</th>
                                <th scope="col">Кол-во</th>
                                <th scope="col">{currency(userData.country)}</th>
                                <th scope="col">{userData.role === 'Dealer' ? 'Всего, без НДС' : 'Всего'}</th>
                                <th scope="col">{userData.role === 'Dealer' ? 'Рекомендуемая розничная цена без НДС' : 'Рекомендуемая розничная цена всего:'}</th>
                            </tr>
                    }
                    </thead>
                    <tbody>
                    {
                        items?.map((item, i) => {
                            index ++
                            return (
                                <tr key={i}>
                                    <th scope='row'>{index}</th>
                                    {
                                        itemDescription(item)
                                    }
                                    {
                                        (location.state.mode === 'recalculate') ?
                                            <React.Fragment>
                                                <td>
                                                    <p contentEditable={true}>
                                                        1
                                                    </p>
                                                </td>
                                                <td>
                                                    <p contentEditable={true}>
                                                        {(item.recommendedPriceEuro * rate).toFixed(2)}
                                                    </p>
                                                </td>
                                                <td>
                                                    {(item.recommendedPriceEuro * rate).toFixed(2)}
                                                </td>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <td>1</td>
                                                <td>{(item.priceEuro * rate).toFixed(2)}</td>
                                                <td>{(item.priceEuro * rate).toFixed(2)}</td>
                                                <td>{(item.recommendedPriceEuro * rate).toFixed(2)}</td>
                                            </React.Fragment>
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        additionalItems?.map((item, i) => {
                            index++
                            return(
                                <tr key={i}>
                                    <th scope="row" >{index}</th>
                                    <td>{item.description}.</td>
                                    {
                                        (location.state.mode === 'recalculate') ?
                                            <React.Fragment>
                                                <td>
                                                    <p contentEditable={true}>
                                                        {item.quantity}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p contentEditable={true}>
                                                        {mult(item.recommendedPriceEuro, rate).toFixed(2)}
                                                    </p>
                                                </td>
                                                <td>
                                                    {mult(item.recommendedPriceEuro * rate, item.quantity).toFixed(2)}
                                                </td>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <td>{item.quantity}</td>
                                                <td>{mult(item.priceEuro, rate).toFixed(2)}</td>
                                                <td>{mult(item.priceEuro * rate, item.quantity).toFixed(2)}</td>
                                                <td>{mult(item.recommendedPriceEuro * rate, item.quantity).toFixed(2)}</td>
                                            </React.Fragment>
                                    }
                                </tr>
                                )
                        })
                    }
                    {
                        addedItems?.map((item, i) => {
                            index++
                            return(
                                <tr key={i}>
                                    <th scope="row" >{index}</th>
                                    <td><p contentEditable={true}>Название товара или услуги</p></td>
                                    <td>
                                        <p contentEditable={true}>
                                            0
                                        </p>
                                    </td>
                                    <td>
                                        <p contentEditable={true}>
                                            0
                                        </p>
                                    </td>
                                    <td></td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {
                            (location.state.mode !== 'recalculate') ?
                                <React.Fragment>
                                    <td>{sumOrder()}</td>
                                    <td>{sumRecomendedPrice()}</td>
                                </React.Fragment>
                                :
                                <td></td>
                        }
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className='print-only'>
                {
                    (location.state.mode === 'recalculate') ?
                        <React.Fragment>
                            {
                                (items) && (
                                    imageObjects(items).map((images) => {
                                        return (
                                            <React.Fragment>
                                                <p className='image-group-p'>{images.name}</p>
                                                {
                                                    images.links.map((link: string) => {
                                                        const img = require(`../../img/ItemsPhoto/${link}`).default
                                                        return (
                                                            <React.Fragment>
                                                                <img src={img} alt='Фото продукта'/>
                                                                <br/>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                )
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>

                        </React.Fragment>
                }
            </div>
            <div className='flex mt-5 justify-center'>
                {
                    (location.state.mode !== 'recalculate') && (
                        <Link className='print:hidden bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded mr-5' to={{ pathname: '/order-form', state: { savedOrder: formData, mode: 'normal'} }}>Вернутся назад</Link>
                    )
                }
                <button className='print:hidden bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 px-4 rounded mr-5' onClick={() => {
                    window.print()
                }}>Распечатать таблицу</button>
                {
                    (userData.mail === 'demo_ru@markiz.by' || userData.mail === 'demo_kz@markiz.by') ?
                            <React.Fragment>
                            </React.Fragment>
                        :
                            (location.state.mode === 'recalculate') ?
                                <React.Fragment>
                                    <button className='print:hidden mr-5 bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded' onClick={() => {
                                        upendAddedItems()
                                    }}>Добавить поле</button>
                                    <button className='print:hidden mr-5 bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded' onClick={()=>{deleteAddedItems(addedItems[addedItems.length - 1])}}>
                                        Удалить поле
                                    </button>
                                    <button className='print:hidden bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded' onClick={()=>{recalculate()}}>
                                        Расчитать
                                    </button>
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <button className='print:hidden bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded' onClick={() => {
                                        sendToServer()
                                    }}>Оформить заказ</button>
                                </React.Fragment>

                }
            </div>
        </div>
    )

}

export default withRouter(OrderSummary)